import moment from 'moment-timezone'

import { httpHelper } from '@/utils'
import UrlHelper from '@/mixins/UrlHelper'

const { VUE_APP_COLOR_ACCENT: colorAccent } = process.env

export default {
    mixins: [UrlHelper],
    props: {
        id: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            chartData: {},
            sharedChartOptions: {
                chart: {
                    group: 'treemonitoring',
                    type: 'line',
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        show: false,
                    },
                    animations: {
                        enabled: false,
                    },
                },
                stroke: {
                    curve: 'straight',
                    width: 3,
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                tooltip: {
                    shared: true,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
                colors: [
                    colorAccent,
                    '#2E93fA',
                    '#66DA26',
                    '#546E7A',
                    '#E91E63',
                    '#FF9800',
                ],
            },
        }
    },
    computed: {
        temperatureSeries() {
            let series = []
            let dataEntries = Object.entries(this.chartData)
            for (let [key, series_for_depth] of dataEntries) {
                let name = this.$t('temperature') + ' ' + (Number(key) + 1)
                if (Number(key) === 0) {
                    name += ' (' + this.$t('top') + ')'
                } else if (Number(key) === dataEntries.length - 1) {
                    name += ' (' + this.$t('bottom') + ')'
                }

                series.push({
                    name: name,
                    data: series_for_depth.temperature,
                })
            }
            return series
        },
        moistureSeries() {
            let series = []
            let dataEntries = Object.entries(this.chartData)
            for (let [key, series_for_depth] of dataEntries) {
                let name = this.$t('moisture') + ' ' + (Number(key) + 1)
                if (Number(key) === 0) {
                    name += ' (' + this.$t('top') + ')'
                } else if (Number(key) === dataEntries.length - 1) {
                    name += ' (' + this.$t('bottom') + ')'
                }

                series.push({
                    name: name,
                    data: series_for_depth.moisture,
                })
            }
            return series
        },
    },
    watch: {
        customRange() {
            this.load()
        },
    },
    mounted() {
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
        })
    },
    methods: {
        async load() {
            this.isLoading = true
            const results = await this.loadData()
            this.setData(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let url =
                'measurements/?' +
                `tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&timestamp_min=${encodeURIComponent(
                    moment(this.customRange.startDate).format()
                )}` +
                `&timestamp_max=${encodeURIComponent(
                    moment(this.customRange.endDate).format()
                )}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = results.concat(data.results)
                url = data.next
            }

            return results
        },
        setData(results) {
            this.chartData = results.reduce(
                (acc, { sensor_data, timestamp }) => {
                    Object.keys(sensor_data).forEach(key => {
                        let num, type

                        if (key.indexOf('moisture_') === 0) {
                            num = key.replace('moisture_', '')
                            type = 'moisture'
                        } else if (key.indexOf('temperature_') === 0) {
                            num = key.replace('temperature_', '')
                            type = 'temperature'
                        }

                        if (num) {
                            acc[num] = acc[num] || {
                                temperature: [],
                                moisture: [],
                            }
                            acc[num][type].push([timestamp, sensor_data[key]])
                        }
                    })

                    return acc
                },
                {}
            )
        },
        chartOptionsMoisture(key) {
            const options = {
                ...this.sharedChartOptions,
                ...{
                    yaxis: {
                        title: {
                            text: this.$t('moisture'),
                        },
                        min: 0,
                        max: 100,
                        tickAmount: 4,
                        decimalsInFloat: 1,
                    },
                },
            }
            options.chart.id = key
            return options
        },
        chartOptionsTemperature(key) {
            const options = {
                ...this.sharedChartOptions,
                ...{
                    yaxis: {
                        title: {
                            text: this.$t('temperature') + ' (℃)',
                        },
                        min: -10,
                        max: 40,
                        tickAmount: 4,
                        decimalsInFloat: 1,
                    },
                },
            }
            options.chart.id = key
            return options
        },
    },
}
